import React, { Component, Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from '@/shared/hooks/withRouter'
import { connect } from 'react-redux'
import _ from 'lodash'
import { handleError } from '@/shared/api'
import * as analytics from '@/shared/utils/analytics'
import * as authUrl from '@/features/auth/utils/url'
import useAuthUrl from '@/features/auth/hooks/useAuthUrl'
import { signUp, signUpOauth } from '@/features/auth/state/actions'
import { Modal, Form, Button, Field, BirthDateSelects } from '@/shared/components'
import googleLogo from '@/features/auth/assets/googleLogo.png'
import '@/features/auth/styles/authModal.scss'
import '@/features/auth/styles/signup.scss'
import { useLocation } from 'react-router-dom'; // Import useLocation

const mapDispatchToProps = { signUp, signUpOauth }

const propTypes = {
  signUp: PropTypes.func.isRequired,
  signUpOauth: PropTypes.func.isRequired
}

const getInitialState = () => ({
  oauthSubmitting: false,
  isEmailFormOpen: false,
  afterIncompleteOauth: false,
  provider: 'email',
  user: undefined,
  oauthToken: undefined,
})

const Signup = (props) => {
  const { isOpenSignup, toCloseSignup, toOpenSignin, toOpenConfirm } = useAuthUrl()

  const [state, setState] = useState(() => getInitialState())

  const location = useLocation(); // Use useLocation to access the URL
  const searchParams = new URLSearchParams(location.search);

  const utmUrl = window.location.href;
  const utmSource = searchParams.get('utm_source') || '';
  const utmMedium = searchParams.get('utm_medium') || '';
  const utmCampaign = searchParams.get('utm_campaign') || '';
  const utmTerm = searchParams.get('utm_term') || '';
  const utmContent = searchParams.get('utm_content') || '';

  const handleSignupOauth = (provider, resetForm) => {
    const utmParams  = {
      utmUrl: utmUrl,
      utmSource: utmSource,
      utmMedium: utmMedium,
      utmCampaign: utmCampaign,
      utmTerm: utmTerm,
      utmContent: utmContent,
    };

    props.signUpOauth({
      provider,
      utmParams,
      setSubmitting: value => {
        setState(prevState => ({
          ...prevState,
          oauthSubmitting: value
        }))
      },
      onSuccess: () => {
        resetForm()
        setState(getInitialState())
        toOpenConfirm()
      },
      onIncomplete: ({ user, oauthToken }) => {
        setState(prevState => ({
          ...prevState,
          oauthSubmitting: false,
          afterIncompleteOauth: true,
          provider,
          user,
          oauthToken,
        }))
      },
    })
  }

  const handleSignupForm = (values, form) => {
    const utmUrlInput = document.querySelector('input[name="utm_url"]');
    const utmSourceInput = document.querySelector('input[name="utm_source"]');
    const utmMediumInput = document.querySelector('input[name="utm_medium"]');
    const utmCampaignInput = document.querySelector('input[name="utm_campaign"]');
    const utmTermInput = document.querySelector('input[name="utm_term"]');
    const utmContentInput = document.querySelector('input[name="utm_content"]');

    values.utmUrlInput = values.utmUrlInput === '' ? utmUrlInput?.dataset?.kwcachedvalue : values.utmUrlInput;
    values.utmSourceInput = values.utmSourceInput === '' ? utmSourceInput?.dataset?.kwcachedvalue : values.utmSourceInput;
    values.utmMediumInput = values.utmMediumInput === '' ? utmMediumInput?.dataset?.kwcachedvalue : values.utmMediumInput;
    values.utmCampaignInput = values.utmCampaignInput === '' ? utmCampaignInput?.dataset?.kwcachedvalue : values.utmCampaignInput;
    values.utmTermInput = values.utmTermInput === '' ? utmTermInput?.dataset?.kwcachedvalue : values.utmTermInput;
    values.utmContentInput = values.utmContentInput === '' ? utmContentInput?.dataset?.kwcachedvalue : values.utmContentInput;

    props.signUp({
      ...values,
      provider: state.provider,
      oauthToken: state.oauthToken,
      rememberMe: true,
    }).then(() => {
      form.resetForm()
      setState(getInitialState())
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        'event': 'signed-up-success',
      })
      toOpenConfirm()
    }, ({ error }) => {
      if (error.details) {
        form.setErrors(error.details)
      } else {
        handleError({ error })
      }
      form.setSubmitting(false)
    })
  }

  const getInitialValues = () => {
    const get = key => _.get(state.user, key) || ''
    const values = {
      email: get('email'),
      firstName: get('firstName'),
      lastName: get('lastName'),
      password: '',
      gender: get('gender'),
      birthDate: get('birthDate'),
      utmUrl,
      utmSource,
      utmMedium,
      utmCampaign,
      utmTerm,
      utmContent,
    }
    return state.afterIncompleteOauth ? _.omit(values, 'password') : values
  }

  const getValidationSchema = () => {
    const schema = {
      email: [Form.is.required(), Form.is.email()],
      firstName: Form.is.required(),
      lastName: Form.is.required(),
      password: [Form.is.required(), Form.is.min(8)],
      gender: Form.is.required(),
      birthDate: Form.is.required(),
    }
    return state.afterIncompleteOauth ? _.omit(schema, 'password') : schema
  }

  const renderFormContent = ({ handleSubmit, isSubmitting, fields, setFieldValue, resetForm }) => (
    <Fragment>
      <div className="authModal_inner">
        <div className="authModal_title">
          Sign Up to Continue
        </div>
        {!state.afterIncompleteOauth && (
          <Fragment>
            <Button
              className="authModal_facebook"
              icon="facebook"
              working={state.oauthSubmitting === 'facebook'}
              onClick={() => handleSignupOauth('facebook', resetForm)}
            >
              Through Facebook
            </Button>
            <Button
              hollow
              className="authModal_google"
              icon={<img src={googleLogo} />}
              working={state.oauthSubmitting === 'google'}
              onClick={() => handleSignupOauth('google', resetForm)}
            >
              Through Google
            </Button>
            <div className="authModal_divider">
              OR
            </div>
            {!state.isEmailFormOpen ? (
              <Button
                className="signup_emailFormLink"
                icon="email"
                onClick={() =>
                  setState(prevState => ({
                    ...prevState,
                    isEmailFormOpen: true
                  }))
                }
              >
                Sign up with Email
              </Button>
            ) : (
              <div className="authModal_subtitle">
                Sign up with Email
              </div>
            )}
          </Fragment>
        )}
        {(state.isEmailFormOpen || state.afterIncompleteOauth) && (
          <form noValidate onSubmit={handleSubmit}>
            <input type="hidden" name="utm_url" value={fields.utmUrl.value} data-kwcachedvalue={fields.utmUrl.value}/>
            <input type="hidden" name="utm_source" value={fields.utmSource.value} data-kwcachedvalue={fields.utmSource.value}/>
            <input type="hidden" name="utm_medium" value={fields.utmMedium.value} data-kwcachedvalue={fields.utmMedium.value}/>
            <input type="hidden" name="utm_campaign" value={fields.utmCampaign.value} data-kwcachedvalue={fields.utmCampaign.value}/>
            <input type="hidden" name="utm_term" value={fields.utmTerm.value} data-kwcachedvalue={fields.utmTerm.value}/>
            <input type="hidden" name="utm_content" value={fields.utmContent.value} data-kwcachedvalue={fields.utmContent.value}/>
            <Field.Input
              {...fields.email}
              placeholder="Email Address"
              autoComplete="username"
              icon="email"
              type="email"
            />
            <div className="signup_names">
              <div className="signup_name">
                <Field.Input
                  {...fields.firstName}
                  placeholder="First Name"
                  autoComplete="given-name"
                  icon="user"
                />
              </div>
              <div className="signup_name">
                <Field.Input
                  {...fields.lastName}
                  placeholder="Last Name"
                  autoComplete="family-name"
                  icon="user"
                />
              </div>
            </div>
            {!state.afterIncompleteOauth && (
              <Field.Input
                {...fields.password}
                type="password"
                autoComplete="new-password"
                spellCheck="false"
                autoCorrect="off"
                autoCapitalize="none"
                placeholder="Password"
                icon="lock"
              />
            )}
            <div className="signup_genderBirth">
              <BirthDateSelects
                label="Select Your Birthdate"
                value={fields.birthDate.value}
                onChange={value => setFieldValue('birthDate', value)}
                errors={fields.birthDate.errors}
              />
              <Field.Select
                {...fields.gender}
                label="Gender"
                placeholder="Select"
                options={[
                  {value: 'male', label: 'Male'},
                  {value: 'female', label: 'Female'},
                ]}
              />
            </div>
            <div className="authModal_tip">
              To sign up, you must be 18 or older. Other people won't see your birthday.
            </div>
            <Button type="submit" working={isSubmitting} className="authModal_submit">
              Sign Up Now
            </Button>
          </form>
        )}
      </div>
      <div className="authModal_bottom">
        Already have a Roomsie account?
        <span
          className="authModal_bottom_link"
          onClick={() => toOpenSignin()}
        >
          Sign In
        </span>
      </div>
    </Fragment>
  )

  return (
    <Form
      enableReinitialize
      initialValues={getInitialValues()}
      validations={getValidationSchema()}
      onSubmit={handleSignupForm}
      render={formProps => (
        isOpenSignup() && (
          <Modal
            isOpen
            containsForm
            onOpen={() => analytics.track('Sign Up Modal Opened')}
            onClose={() => {
              analytics.track('Sign Up Modal Closed')
              toCloseSignup()
            }}
            className="authModal signup"
            renderContent={() => (
              renderFormContent(formProps)
            )}
          />
        )
      )}
    />
  )
}

Signup.propTypes = propTypes

export default withRouter(connect(null, mapDispatchToProps)(Signup))
